import React from 'react';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';

export default ({ location }) => (
  <Layout location={location}>
    <Helmet title="Panier" />
    <section className="px-10 py-10" style={{ minHeight: 'calc(100vh - 80px - 415px' }}>
      <h1>Panier</h1>
      <p>
        Laboris dolore id tempor proident mollit exercitation anim nostrud magna elit Lorem. Minim tempor adipisicing
        amet culpa reprehenderit cupidatat magna et incididunt ad mollit. Excepteur do ut nulla anim aliqua ullamco ea
        anim quis fugiat labore labore occaecat. Incididunt exercitation pariatur incididunt anim quis et.
      </p>
      <p>
        Proident excepteur sint pariatur ea culpa commodo sunt esse pariatur ullamco deserunt. Magna occaecat ex
        excepteur laborum sunt reprehenderit culpa sunt. Non non occaecat ad incididunt nisi et. Irure occaecat dolore
        deserunt laborum aliqua proident dolore ut aute pariatur labore aute. Tempor exercitation id ullamco sint
        commodo proident elit reprehenderit mollit commodo dolor qui id.
      </p>
      <p>
        Cras et finibus odio, et semper purus. Donec facilisis hendrerit nulla, a consequat nisl dictum sed. In sit amet
        vestibulum neque, id ultricies leo. Sed semper eget felis nec varius. In massa nunc, convallis quis enim ut,
        varius ornare neque. Nullam aliquet risus a iaculis luctus. Vivamus id urna eleifend eros luctus pellentesque.
        Aliquam gravida molestie nunc non ultricies. Etiam eleifend consectetur quam et viverra. Nunc sagittis, tortor
        id interdum rutrum, sapien ante bibendum ex, non cursus leo lorem a ipsum. Quisque sem lorem, feugiat sed quam
        id, lobortis aliquam nisl. Aenean aliquam velit et ultrices maximus. Vivamus vehicula imperdiet augue, quis
        viverra felis lacinia sit amet.
      </p>
    </section>
  </Layout>
);
